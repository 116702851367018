* {
  box-sizing: border-box;
}

p,
a,
ul,
li {
  font-family: 'Roboto';
}

.fl {
  display: flex;
}

.fl-col {
  flex-direction: column;
}

.jc-c {
  justify-content: center;
}

.fw-w {
  flex-wrap: wrap;
}

.jc-sb {
  justify-content: space-between;
}

.ai-c {
  align-items: center;
}

.canvas {
  width: 100%;
  height: 150px;
  background-color: #fff;
}

@media only screen and (min-width: 360px) {
  .canvas {
    height: 350px;
  }
}

@media only screen and (min-width: 360px) and (max-height: 630px) {
  .canvas {
    height: 260px;
  }
}

@media only screen and (min-width: 400px) {
  .canvas {
    /* height: 255px; */
  }
}

@media (orientation: landscape) {
  #root .canvas {
    height: 220px;
  }
}

@media only screen and (max-height: 375px) and (orientation: landscape) {
  .canvas {
    height: 200px;
  }
}

/* iPhone 5/SE Landscape */
@media only screen and (max-height: 340px) and (orientation: landscape) {
  #root .canvas {
    height: 150px;
  }
}
